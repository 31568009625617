import { debugWrapper, getClientLogConfig, getClientStates } from '@amplitude/analytics-core';
import { AmplitudeBrowser } from './browser-client';
export var createInstance = function () {
    var client = new AmplitudeBrowser();
    return {
        init: debugWrapper(client.init.bind(client), 'init', getClientLogConfig(client), getClientStates(client, ['config'])),
        add: debugWrapper(client.add.bind(client), 'add', getClientLogConfig(client), getClientStates(client, ['config.apiKey', 'timeline.plugins'])),
        remove: debugWrapper(client.remove.bind(client), 'remove', getClientLogConfig(client), getClientStates(client, ['config.apiKey', 'timeline.plugins'])),
        track: debugWrapper(client.track.bind(client), 'track', getClientLogConfig(client), getClientStates(client, ['config.apiKey', 'timeline.queue.length'])),
        logEvent: debugWrapper(client.logEvent.bind(client), 'logEvent', getClientLogConfig(client), getClientStates(client, ['config.apiKey', 'timeline.queue.length'])),
        identify: debugWrapper(client.identify.bind(client), 'identify', getClientLogConfig(client), getClientStates(client, ['config.apiKey', 'timeline.queue.length'])),
        groupIdentify: debugWrapper(client.groupIdentify.bind(client), 'groupIdentify', getClientLogConfig(client), getClientStates(client, ['config.apiKey', 'timeline.queue.length'])),
        setGroup: debugWrapper(client.setGroup.bind(client), 'setGroup', getClientLogConfig(client), getClientStates(client, ['config.apiKey', 'timeline.queue.length'])),
        revenue: debugWrapper(client.revenue.bind(client), 'revenue', getClientLogConfig(client), getClientStates(client, ['config.apiKey', 'timeline.queue.length'])),
        flush: debugWrapper(client.flush.bind(client), 'flush', getClientLogConfig(client), getClientStates(client, ['config.apiKey', 'timeline.queue.length'])),
        getUserId: debugWrapper(client.getUserId.bind(client), 'getUserId', getClientLogConfig(client), getClientStates(client, ['config', 'config.userId'])),
        setUserId: debugWrapper(client.setUserId.bind(client), 'setUserId', getClientLogConfig(client), getClientStates(client, ['config', 'config.userId'])),
        getDeviceId: debugWrapper(client.getDeviceId.bind(client), 'getDeviceId', getClientLogConfig(client), getClientStates(client, ['config', 'config.deviceId'])),
        setDeviceId: debugWrapper(client.setDeviceId.bind(client), 'setDeviceId', getClientLogConfig(client), getClientStates(client, ['config', 'config.deviceId'])),
        reset: debugWrapper(client.reset.bind(client), 'reset', getClientLogConfig(client), getClientStates(client, ['config', 'config.userId', 'config.deviceId'])),
        getSessionId: debugWrapper(client.getSessionId.bind(client), 'getSessionId', getClientLogConfig(client), getClientStates(client, ['config'])),
        setSessionId: debugWrapper(client.setSessionId.bind(client), 'setSessionId', getClientLogConfig(client), getClientStates(client, ['config'])),
        extendSession: debugWrapper(client.extendSession.bind(client), 'extendSession', getClientLogConfig(client), getClientStates(client, ['config'])),
        setOptOut: debugWrapper(client.setOptOut.bind(client), 'setOptOut', getClientLogConfig(client), getClientStates(client, ['config'])),
        setTransport: debugWrapper(client.setTransport.bind(client), 'setTransport', getClientLogConfig(client), getClientStates(client, ['config'])),
    };
};
export default createInstance();
