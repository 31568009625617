import { __extends } from "tslib";
import { getGlobalScope } from '@amplitude/analytics-client-common';
import { BrowserStorage } from './browser-storage';
var SessionStorage = /** @class */ (function (_super) {
    __extends(SessionStorage, _super);
    function SessionStorage() {
        var _a;
        return _super.call(this, (_a = getGlobalScope()) === null || _a === void 0 ? void 0 : _a.sessionStorage) || this;
    }
    return SessionStorage;
}(BrowserStorage));
export { SessionStorage };
