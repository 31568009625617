import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import { CampaignParser, getGlobalScope } from '@amplitude/analytics-client-common';
import { IdentifyOperation, } from '@amplitude/analytics-types';
import { BASE_CAMPAIGN } from '@amplitude/analytics-client-common';
import { omitUndefined } from './utils';
export var defaultPageViewEvent = '[Amplitude] Page Viewed';
export var pageViewTrackingPlugin = function (options) {
    if (options === void 0) { options = {}; }
    var amplitude;
    var globalScope = getGlobalScope();
    var loggerProvider = undefined;
    var pushState;
    var localConfig;
    var trackOn = options.trackOn, trackHistoryChanges = options.trackHistoryChanges, _a = options.eventType, eventType = _a === void 0 ? defaultPageViewEvent : _a;
    var createPageViewEvent = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _b = {
                        event_type: eventType
                    };
                    _a = [{}];
                    return [4 /*yield*/, getCampaignParams()];
                case 1: return [2 /*return*/, (_b.event_properties = __assign.apply(void 0, [__assign.apply(void 0, _a.concat([(_c.sent())])), { '[Amplitude] Page Domain': 
                            /* istanbul ignore next */ (typeof location !== 'undefined' && location.hostname) || '', '[Amplitude] Page Location': 
                            /* istanbul ignore next */ (typeof location !== 'undefined' && location.href) || '', '[Amplitude] Page Path': 
                            /* istanbul ignore next */ (typeof location !== 'undefined' && location.pathname) || '', '[Amplitude] Page Title': /* istanbul ignore next */ (typeof document !== 'undefined' && document.title) || '', '[Amplitude] Page URL': 
                            /* istanbul ignore next */ (typeof location !== 'undefined' && location.href.split('?')[0]) || '' }]),
                        _b)];
            }
        });
    }); };
    var shouldTrackOnPageLoad = function () { return typeof trackOn === 'undefined' || (typeof trackOn === 'function' && trackOn()); };
    /* istanbul ignore next */
    var previousURL = typeof location !== 'undefined' ? location.href : null;
    var trackHistoryPageView = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newURL, shouldTrackPageView, _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    newURL = location.href;
                    shouldTrackPageView = shouldTrackHistoryPageView(trackHistoryChanges, newURL, previousURL || '') && shouldTrackOnPageLoad();
                    // Note: Update `previousURL` in the same clock tick as `shouldTrackHistoryPageView()`
                    // This was previously done after `amplitude?.track(await createPageViewEvent());` and
                    // causes a concurrency issue where app triggers `pushState` twice with the same URL target
                    // but `previousURL` is only updated after the second `pushState` producing two page viewed events
                    previousURL = newURL;
                    if (!shouldTrackPageView) return [3 /*break*/, 4];
                    /* istanbul ignore next */
                    loggerProvider === null || loggerProvider === void 0 ? void 0 : loggerProvider.log('Tracking page view event');
                    if (!(amplitude === null || amplitude === void 0)) return [3 /*break*/, 1];
                    _a = void 0;
                    return [3 /*break*/, 3];
                case 1:
                    _c = (_b = amplitude).track;
                    return [4 /*yield*/, createPageViewEvent()];
                case 2:
                    _a = _c.apply(_b, [_d.sent()]);
                    _d.label = 3;
                case 3:
                    _a;
                    _d.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    /* istanbul ignore next */
    var trackHistoryPageViewWrapper = function () {
        void trackHistoryPageView();
    };
    var plugin = {
        name: '@amplitude/plugin-page-view-tracking-browser',
        type: 'enrichment',
        setup: function (config, client) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        amplitude = client;
                        localConfig = config;
                        loggerProvider = config.loggerProvider;
                        loggerProvider.log('Installing @amplitude/plugin-page-view-tracking-browser');
                        if (globalScope) {
                            globalScope.addEventListener('popstate', trackHistoryPageViewWrapper);
                            // Save reference to original push state, to be used in teardown
                            // eslint-disable-next-line @typescript-eslint/unbound-method
                            pushState = globalScope.history.pushState;
                            /* istanbul ignore next */
                            // There is no global browser listener for changes to history, so we have
                            // to modify pushState directly.
                            // https://stackoverflow.com/a/64927639
                            // eslint-disable-next-line @typescript-eslint/unbound-method
                            globalScope.history.pushState = new Proxy(globalScope.history.pushState, {
                                apply: function (target, thisArg, _a) {
                                    var _b = __read(_a, 3), state = _b[0], unused = _b[1], url = _b[2];
                                    target.apply(thisArg, [state, unused, url]);
                                    void trackHistoryPageView();
                                },
                            });
                        }
                        if (!shouldTrackOnPageLoad()) return [3 /*break*/, 2];
                        loggerProvider.log('Tracking page view event');
                        _b = (_a = amplitude).track;
                        return [4 /*yield*/, createPageViewEvent()];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        _c.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); },
        execute: function (event) { return __awaiter(void 0, void 0, void 0, function () {
            var pageViewEvent;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(trackOn === 'attribution' && isCampaignEvent(event))) return [3 /*break*/, 2];
                        /* istanbul ignore next */ // loggerProvider should be defined by the time execute is invoked
                        loggerProvider === null || loggerProvider === void 0 ? void 0 : loggerProvider.log('Enriching campaign event to page view event with campaign parameters');
                        return [4 /*yield*/, createPageViewEvent()];
                    case 1:
                        pageViewEvent = _a.sent();
                        event.event_type = pageViewEvent.event_type;
                        event.event_properties = __assign(__assign({}, event.event_properties), pageViewEvent.event_properties);
                        _a.label = 2;
                    case 2:
                        // Update the pageCounter for the page view event
                        if (localConfig && event.event_type === eventType) {
                            localConfig.pageCounter = !localConfig.pageCounter ? 1 : localConfig.pageCounter + 1;
                            event.event_properties = __assign(__assign({}, event.event_properties), { '[Amplitude] Page Counter': localConfig.pageCounter });
                        }
                        return [2 /*return*/, event];
                }
            });
        }); },
        teardown: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (globalScope) {
                    globalScope.removeEventListener('popstate', trackHistoryPageViewWrapper);
                    if (pushState) {
                        globalScope.history.pushState = pushState;
                    }
                }
                return [2 /*return*/];
            });
        }); },
    };
    return plugin;
};
var getCampaignParams = function () { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
    switch (_b.label) {
        case 0:
            _a = omitUndefined;
            return [4 /*yield*/, new CampaignParser().parse()];
        case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
    }
}); }); };
var isCampaignEvent = function (event) {
    if (event.event_type === '$identify' && event.user_properties) {
        var properties = event.user_properties;
        var $set = properties[IdentifyOperation.SET] || {};
        var $unset = properties[IdentifyOperation.UNSET] || {};
        var userProperties_1 = __spreadArray(__spreadArray([], __read(Object.keys($set)), false), __read(Object.keys($unset)), false);
        return Object.keys(BASE_CAMPAIGN).every(function (value) { return userProperties_1.includes(value); });
    }
    return false;
};
export var shouldTrackHistoryPageView = function (trackingOption, newURL, oldURL) {
    switch (trackingOption) {
        case 'pathOnly':
            return newURL.split('?')[0] !== oldURL.split('?')[0];
        default:
            return newURL !== oldURL;
    }
};
