import { AnalyticsConnector } from '@amplitude/analytics-connector';
export var getAnalyticsConnector = function (instanceName) {
    if (instanceName === void 0) { instanceName = '$default_instance'; }
    return AnalyticsConnector.getInstance(instanceName);
};
export var setConnectorUserId = function (userId, instanceName) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getAnalyticsConnector(instanceName).identityStore.editIdentity().setUserId(userId).commit();
};
export var setConnectorDeviceId = function (deviceId, instanceName) {
    getAnalyticsConnector(instanceName).identityStore.editIdentity().setDeviceId(deviceId).commit();
};
